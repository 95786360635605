.shop .category {
  padding: 30px;
  height: 90vh;
}
.chead h1 {
  font-size: 20px;
  margin-bottom: 20px;
}
.chead h1:nth-child(2) {
  opacity: 0.5;
  border-left: 3px solid rgba(0, 0, 0, 0.5);
  padding-left: 70px;
}
.shop .category .box {
  background-color: #f6f9fc;
  margin: 15px 0;
  padding: 5px 10px;
  transition: 0.5s;
  border-radius: 5px;
}
.shop .category img {
  width: auto;
  height: auto;
}
.shop .category .box:hover {
  background-color: #fff;
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}
.shop .box2 {
  margin-top: 100px !important;
  text-align: center;
}
.shop .category button {
  padding: 10px;
  background: none;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
}

.shop .contentWidth {
  margin-left: 30px;
}

.grid1 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.shop .product img {
  width: 100%;
  height: 100%;
}
.shop .product {
  transition: 0.5s;
}
.shop .product:hover {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}


.pagination {
  list-style: none;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;
}

.pagination-page {
  background-color: #f6f9fc;
  border-radius: 50%;
  padding: 2px 12px;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
  cursor: pointer;

}
.pagination-active {
  background-color: #7A1928;
  color: white !important;
  border-radius: 50%;
}
.pagination-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 100%;
}
.page-item {
  list-style: none;
  padding: 2px 12px;
  height: 31.5px;
  width: 31.5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2px;
}


.srv .service-img {
  cursor: pointer;
  height: 300px;
  overflow: hidden;

}

.srv .service-img img {
  object-fit: cover !important;
  height: 100%;
  width: 100%;

  overflow: hidden;

}

.rtl {
  direction: rtl;

}
.discounted-price {
  margin-right: 10px;
  font-size: 0.8rem;
  color: #7A1928;
  text-decoration: line-through;

}

.customize-btn {
  padding: 5px 20px;
  background: none;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  background: #7A1928;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.customize-btn:hover {
  background: #fff;
  color: #7A1928;
}
.product-details2 {
  padding: 20px;
}
