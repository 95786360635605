.product-wrapper a {
    text-decoration: none;
  }
  
  .product-wrapper ul,
  .product-wrapper ol {
    list-style: none;
  }
  
  .product-wrapper img {
    display: block;
    max-width: 100%;
    object-fit: cover;
    background-size: cover;
  }
  
  .product-wrapper p {
    line-height: 1.7;
    font-size: 16px;
  }
  
  .product-wrapper  button,
  .product-wrapper  .button {
    border: none;
    background: none;
    font-size: 16px;
    font-family: inherit;
    font-weight: inherit;
    cursor: pointer;
    border-radius: 6px;
    transition: 0.2s linear;
  }
  
  .product-wrapper  button:focus-visible,
  .product-wrapper .button:focus-visible {
    outline: 1px dashed #68707d;
  }
  
  .product-wrapper button:hover,
  .product-wrapper .button:hover {
    opacity: 0.7;
  }
  
  .product-wrapper .container {
    padding-right: 24px;
    padding-left: 24px;
  }
  
  @media (min-width: 64em) {
    .product-wrapper  .container {
      max-width: 1110px;
      margin: 0 auto;
    }
  }
  
  .product-wrapper  .flex {
    display: flex;
  }
  
  .product-wrapper  .flex-ai-c {
    align-items: center;
  }
  
  .product-wrapper .flex-jc-sb {
    justify-content: space-between;
  }
  
  .product-wrapper  .flex-jc-c {
    justify-content: center;
  }
  
  @media (max-width: 63.9375em) {
    .product-wrapper .hide-for-mobile {
      display: none !important;
    }
  }
  
  @media (min-width: 64em) {
    .product-wrapper  .hide-for-desktop {
      display: none;
    }
  }
  
  .product-wrapper  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9;
    background-color: rgba(0, 0, 0, 0.75);
    opacity: 0;
    visibility: hidden;
    transform: 0.2s linear;
  }
  
  .product-wrapper  .overlay.open {
    opacity: 1;
    visibility: visible;
  }
  
  .product-wrapper .header nav {
    position: relative;
    padding: 25px 0;
  }
  
  @media (min-width: 40em) {
    .product-wrapper  .header nav {
      border-bottom: 1px solid #b6bcc8;
    }
  }
  
  @media (min-width: 64em) {
    .product-wrapper  .header nav {
      padding: 45px 0;
    }
  }
  
  .product-wrapper  .header .toggle-menu {
    margin-right: 15px;
    cursor: pointer;
  }
  
  @media (min-width: 64em) {
    .product-wrapper  .header .main-navgation {
      align-self: flex-start;
      margin-left: 50px;
    }
    .product-wrapper .header .main-navgation a {
      position: relative;
      font-size: 14px;
      color: #68707d;
      margin-right: 33px;
      transition: 0.2s linear;
    }
    .product-wrapper .header .main-navgation a:hover {
      color: #1d2025;
    }
    .product-wrapper   .header .main-navgation a::after {
      position: absolute;
      content: "";
      left: 0;
      bottom: -62px;
      width: 100%;
      height: 5px;
      opacity: 0;
      background-color: #7A1928;
      transition: 0.2s linear;
    }
    .product-wrapper  .header .main-navgation a:hover::after {
      opacity: 1;
    }
  }
  
  .product-wrapper .header .mobile-navgation {
    position: fixed;
    top: 0;
    left: 0;
    transform: translateX(-100%);
    height: 100%;
    width: 250px;
    z-index: 10;
    padding: 25px;
    background-color: white;
    transition: transform 0.2s linear;
  }
  
  .product-wrapper .header .mobile-navgation.open {
    transform: translateX(0);
  }
  
  @media (min-width: 40em) {
    .product-wrapper   .header .mobile-navgation {
      width: 350px;
    }
  }
  
  .product-wrapper  .header .mobile-navgation .close-menu {
    display: block;
    margin-bottom: 55px;
    cursor: pointer;
  }
  
  .product-wrapper  .header .mobile-navgation a {
    display: block;
    color: #1d2025;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: 18px;
    transition: 0.2s linear;
  }
  
  .product-wrapper .header .mobile-navgation a:hover {
    padding-left: 10px;
    color: #68707d;
  }
  
  .product-wrapper .header .controls {
    gap: 22px;
  }
  
  @media (min-width: 64em) {
    .product-wrapper  .header .controls {
      gap: 40px;
    }
  }
  
  .product-wrapper  .header .user-avater {
    width: 30px;
    height: 30px;
    cursor: pointer;
    overflow: hidden;
    border-radius: 50%;
    border: 2px solid transparent;
    transition: 0.2s linear;
  }
  
  @media (min-width: 64em) {
    .product-wrapper  .header .user-avater {
      width: 50px;
      height: 50px;
    }
  }
  
  .product-wrapper  .header .user-avater:hover {
    border-color: #7A1928;
  }
  
  .product-wrapper  .header .user-avater img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .product-wrapper .cart.open .cart-list-wrapper {
    top: calc(100% + 10px);
    opacity: 1;
    visibility: visible;
  }
  
  @media (min-width: 40em) {
    .product-wrapper  .cart.open .cart-list-wrapper {
      top: calc(100% + -15px);
    }
  }
  
  @media (min-width: 64em) {
    .product-wrapper .cart.open .cart-list-wrapper {
      top: calc(100% + -30px);
    }
  }
  
  .product-wrapper .cart.show-count .cart-icon .in-cart {
    opacity: 1;
    visibility: visible;
  }
  
  .product-wrapper  .cart.empty .cart-content {
    height: 185px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
  }
  
  .product-wrapper  .cart .cart-icon {
    position: relative;
    cursor: pointer;
  }
  
  .product-wrapperé  .cart .cart-icon .in-cart {
    position: absolute;
    top: -7px;
    right: -10px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 15px;
    color: white;
    font-size: 14px;
    text-align: center;
    border-radius: 25px;
    background-color: #7A1928;
    opacity: 0;
    visibility: hidden;
    transition: 0.2s linear, visibility 0.3s linear;
  }
  
  .product-wrapper .cart .cart-icon svg path {
    transition: 0.2s linear;
  }
  
  .product-wrapper .cart .cart-icon:hover path {
    fill: #1d2025;
  }
  
  .product-wrapper .cart .cart-list-wrapper {
    position: absolute;
    top: calc(100% + 20px);
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% + 20px);
    opacity: 0;
    visibility: hidden;
    z-index: 8;
    background-color: white;
    border-radius: 6px;
    box-shadow: 2px 5px 15px rgba(0, 0, 0, 0.2);
    transition: 0.2s linear, visibility 0.3s linear;
  }
  
  @media (min-width: 40em) {
    .product-wrapper .cart .cart-list-wrapper {
      top: 100%;
      width: 360px;
      right: 0;
      left: initial;
      transform: initial;
    }
  }
  
  @media (min-width: 64em) {
    .product-wrapper .cart .cart-list-wrapper {
      top: calc(100% + -15px);
    }
  }
  
  .product-wrapper .cart .cart-list-wrapper .cart-heading {
    color: #1d2025;
    padding: 25px;
    border-bottom: 1px solid #b6bcc8;
  }
  
  .product-wrapper .cart .cart-list-wrapper .cart-content {
    padding: 25px 25px 30px 25px;
  }
  
  .product-wrapper  .cart-content .cart-list .cart-item {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
  }
  
  .product-wrapper .cart-content .checkout-btn {
    width: 100%;
    color: white;
    padding: 15px 20px;
    background-color: #7A1928;
  }
  
  .product-wrapper .cart-list .cart-item {
    margin-bottom: 25px;
  }
  
  .product-wrapper .cart-list .cart-item .item-image {
    flex: 0 0 50px;
    overflow: hidden;
    border-radius: 6px;
  }
  
  .product-wrapper  .cart-list .cart-item .item-info {
    flex: auto;
    color: #68707d;
  }
  
  .product-wrapper .cart-list .cart-item .item-title {
    font-weight: 400;
    margin-bottom: 5px;
  }
  
  .product-wrapper .cart-list .cart-item .item-price-wrapper {
    display: flex;
  }
  
  .product-wrapper  .cart-list .cart-item .item-price-wrapper .item-count {
    margin: 0 10px 0 5px;
  }
  
  .product-wrapper .cart-list .cart-item .item-price-wrapper .total-price {
    font-weight: bold;
    font-size: 18px;
    color: #1d2025;
  }
  
  .product-wrapper .cart-list .cart-item .item-delete {
    transition: 0.2s linear;
  }
  
  .product-wrapper .cart-list .cart-item .item-delete:hover {
    cursor: pointer;
    transform: scale(1.08);
  }
  
  .product-wrapper .cart-list .cart-item .item-delete:hover img {
    filter: invert(17%) sepia(55%) saturate(7234%) hue-rotate(341deg) brightness(88%) contrast(95%);
  }
  
  .product-wrapper .container {
    display: flex;
    flex-direction: column;
    padding: 0;
  }
  
  @media (min-width: 40em) {
    .product-wrapper .container {
      padding: 90px 24px;
    }
  }
  
  @media (min-width: 64em) {
    .product-wrapper .container {
      flex-direction: row;
      gap: 120px;
      padding: 90px 50px;
    }
  }
  
  .product-wrapper  .product-images-wrapper {
    display: flex;
    flex-direction: column;
    row-gap: 25px;
    user-select: none;
  }
  
  @media (min-width: 40em) {
    .product-wrapper  .product-images-wrapper {
      max-width: 440px;
      margin: auto;
    }
  }
  
  .product-wrapper  .preview-image-wrapper {
    position: relative;
    cursor: pointer;
  }
  
  @media (min-width: 40em) {
    .product-wrapper .preview-image-wrapper .preview-image {
      border-radius: 15px;
    }
  }
  
  .product-wrapper .arrows {
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
  }
  
  .product-wrapper .arrows > div {
    position: absolute;
    width: 40px;
    height: 40px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .product-wrapper .arrows > div img {
    transition: 100ms linear;
  }
  
  .product-wrapper .arrows > div:hover img {
    filter: invert(52%) sepia(91%) saturate(2132%) hue-rotate(349deg) brightness(108%) contrast(101%);
  }
  
  .product-wrapper .arrows .next {
    left: calc(100% - (15px + 40px));
  }
  
  .product-wrapper .arrows .prev {
    left: 15px;
  }
  
  .product-wrapper .count {
    position: absolute;
    bottom: -35px;
    display: none;
  }
  
  @media (max-width: 63.9375em) {
    .product-wrapper  .count {
      right: 4px;
      display: block;
    }
  }
  
  @media (max-width: 39.9375em) {
    .product-wrapper  .count {
      right: 15px;
    }
  }
  
  .product-wrapper  .count p {
    font-size: 16px;
  }
  
  .product-wrapper .count span {
    font-weight: bold;
  }
  
  .product-wrapper .thumbs-wrapper {
    display: flex;
    gap: 25px;
  }
  
  .product-wrapper .thumbs-wrapper > div {
    position: relative;
    max-width: 92px;
    cursor: pointer;
    overflow: hidden;
    user-select: none;
    border-radius: 10px;
    border: 2px solid transparent;
    transition: 0.2s linear;
  }
  
  .product-wrapper .thumbs-wrapper > div.active {
    border-color: #7A1928;
  }
  
  .product-wrapper .thumbs-wrapper > div:hover::after, .thumbs-wrapper > div.active::after {
    opacity: 1;
  }
  
  .product-wrapper .thumbs-wrapper > div::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: rgba(255, 255, 255, 0.5);
    transition: 0.2s linear;
  }
  
  .product-wrapper .thumbs-wrapper > div img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .product-wrapper .product-details-wrapper {
    padding: 24px;
    font-weight: 700;
  }
  
  @media (min-width: 40em) {
    .product-wrapper .product-details-wrapper {
      padding: 45px 0;
    }
  }
  
  @media (min-width: 64em) {
    .product-wrapper .product-details-wrapper {
      padding: 65px 0;
    }
  }
  
  .product-wrapper .product-details-wrapper .product-brabd {
    font-size: 14px;
    color: #7A1928;
    letter-spacing: 1px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }
  
  .product-wrapper .product-details-wrapper .product-title {
    margin-bottom: 25px;
    color: #1d2025;
    font-size: 35px;
    line-height: 1.1;
  }
  
  @media (min-width: 64em) {
    .product-wrapper .product-details-wrapper .product-title {
      font-size: 48px;
      margin-bottom: 40px;
    }
  }
  
  .product-wrapper .product-details-wrapper .product-description {
    margin-bottom: 30px;
    font-weight: 400;
  }
  
  .product-wrapper .product-details-wrapper .current-price-wrapper {
    display: flex;
    align-items: center;
    gap: 15px;
    margin-bottom: 15px;
  }
  
  .product-wrapper .product-details-wrapper .current-price-wrapper .current-price {
    color: #1d2025;
    font-size: 30px;
  }
  
  .product-wrapper .product-details-wrapper .current-price-wrapper .discount {
    position: relative !important;
    background: #ffede0;
    color: #7A1928;
    padding: 5px 10px;
    border-radius: 6px;
  }
  
  .product-wrapper .product-details-wrapper .previous-price-wrapper .previous-price {
    color: #b6bcc8;
    text-decoration: line-through;
    font-size: 17px;
  }
  
  .product-wrapper  .product-details-wrapper .add-to-cart-form {
    position: relative;
    margin-top: 40px;
  }
  
  @media (min-width: 40em) {
    .product-wrapper .product-details-wrapper .add-to-cart-form {
      display: flex;
      align-items: center;
      gap: 15px;
    }
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form.alert .form-alert {
    top: calc(100% + 10px);
    opacity: 1;
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .product-quantity {
    flex-basis: 155px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #1d2025;
    padding: 15px 20px;
    border-radius: 6px;
    background-color: #f7f8fd;
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .product-quantity-num {
    font-size: 17px;
  }
  
  .product-wrapper  .product-details-wrapper .add-to-cart-form .plus,
  .product-wrapper .product-details-wrapper .add-to-cart-form .minus {
    cursor: pointer;
    transition: 0.2s linear;
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .plus:hover,
  .product-wrapper  .product-details-wrapper .add-to-cart-form .minus:hover {
    opacity: 0.6;
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .add-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-basis: 270px;
    gap: 15px;
    width: 100%;
    color: white;
    padding: 15px 20px;
    margin-top: 15px;
    background-color: #7A1928;
    box-shadow: 0 15px 15px #ffeadb;
  }
  
  @media (min-width: 40em) {
    .product-wrapper  .product-details-wrapper .add-to-cart-form .add-btn {
      margin-top: 0;
    }
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .add-btn img {
    filter: brightness(100);
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .form-alert {
    position: absolute;
    top: calc(100% + 1px);
    opacity: 0;
    transition: 0.2s linear;
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .form-alert.success {
    color: #4cae4f;
  }
  
  .product-wrapper .product-details-wrapper .add-to-cart-form .form-alert.fail {
    color: #db143c;
  }
  
  .product-wrapper .lightbox-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.75);
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.2s linear, visibility 0.3s linear;
  }
  
  .product-wrapper .lightbox-wrapper.open {
    opacity: 1;
    visibility: visible;
  }
  
  .product-wrapper .lightbox-wrapper .lightbox-content {
    max-width: 90%;
    margin: auto;
  }
  
  .product-wrapper .lightbox-wrapper .lightbox-content .product-images-wrapper {
    max-width: 500px;
    gap: 35px;
  }
  
  .product-wrapper .lightbox-wrapper .preview-image-wrapper .arrows > div {
    width: 60px;
    height: 60px;
  }
  
  .product-wrapper .lightbox-wrapper .preview-image-wrapper .arrows .next {
    left: 100%;
    transform: translateX(-50%);
  }
  
  .product-wrapper .lightbox-wrapper .preview-image-wrapper .arrows .prev {
    left: 0;
    transform: translateX(-50%);
  }
  
  .product-wrapper .lightbox-wrapper .preview-image-wrapper .count {
    color: #fff;
  }
  
  .product-wrapper .lightbox-wrapper .preview-image-wrapper .close-lightbox {
    position: absolute;
    right: 0;
    top: -40px;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;
  }
  
  .product-wrapper .lightbox-wrapper .preview-image-wrapper .close-lightbox svg path {
    transition: 0.2s linear;
  }
  
  .product-wrapper .lightbox-wrapper .preview-image-wrapper .close-lightbox:hover svg path {
    fill: #7A1928;
  }
  
  .product-wrapper .product-wrapper .lightbox-wrapper .preview-image-wrapper .close-lightbox img {
    filter: brightness(100%);
  }
  
  .product-wrapper .product-wrapper  .lightbox-wrapper .thumbs-wrapper {
    justify-content: center;
  }
  
  .product-wrapper  .lightbox-wrapper .thumbs-wrapper > div {
    width: 95px;
    height: 95px;
  }

  .flash {
    background: #f6f9fc;
    padding: 50px 0;
  }
  
  
  .flash .img {
    cursor: pointer;
  }
  
  .flash img {
    object-fit: contain;
    width: 100%;
  }
  
  .discounted-price {
    margin-right: 10px;
    font-size: 0.8rem;
    color: #7A1928;
    text-decoration: line-through;
  
  }
  
  