.forum {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

}

.cats {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 10%;
    height: 100vh;
    background-color: #7A1928;
    padding-left: 20px;
    padding-right: 20px;
}

.cats h2 {
    color: #fff;
    font-size: 1.5em;
    margin-top: 100px;
text-align: left;
}

.cats-list {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-top: 20px;

}

.cat {
    width: 100%;
    padding: 5px;
    color: #fff;
    font-size: 1em;
    text-align: left;
    cursor: pointer;

}
.topics {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 100vh;
}

.topic-search  {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100px;

}

.topic-search input {
    width: 50%;
    height: 50px;
    border-radius: 25px;
    padding: 0 20px;
    font-size: 1.2em;
    border: 1px solid #ccc;
}

.topic-search button {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #7A1928;
    color: #fff;
    font-size: 1.2em;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
    cursor: pointer;
}

.topic-search  button i {
    color: #fff;
    font-size: 0.8em;

}

.topics-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    padding: 20px;
    width: 100%;

}
.topic {
    margin-bottom: 20px;
    margin-left: 50px;
    padding: 20px;
    height: 150px;
    width: 600px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}

.topic-user {
    font-size: 1.2em;
    font-weight: bold;
    width: 30%;
    display: flex;
    align-items: center;
    flex-direction: column;

}

.topic-user img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    margin-bottom: 10px;
}


.topic-content {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;

}

.topic-content h3 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.topic-content p {
    margin-top: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
}

.topic .actions {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 20px;
}

.topic .actions i {
    margin-right: 10px;
    text-decoration: none;
    font-size: 1.2em;
}

.topic .actions .fa-heart {
    color: #7A1928;
}

.topic .actions .fa-comment {
    color: #ccc;
}

.add-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.add-container form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
}

.add-container form input {
    width: 100%;
    height: 50px;
    border-radius: 25px;
    padding: 0 20px;
    font-size: 1.2em;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

.add-container form textarea {
    width: 100%;
    height: 200px;
    border-radius: 25px;
    padding: 20px;
    font-size: 1.2em;
    border: 1px solid #ccc;
    margin-bottom: 20px;
}

.add-contanier .form-group {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 20px;

}
.add-container .content-editor {
    width: 100%;
    height: 200px;
    border-radius: 25px;
    padding: 20px;
    font-size: 1.2em;
    margin-bottom: 20px;
}