.terms {
    margin: 0 auto;

    padding: 100px;
}

.terms h1 {
    font-size: 3em;
    margin-bottom: 20px;
}

.terms h2 {
    font-size: 3rem;
    margin-bottom: 10px;
}

.terms h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.terms p {
    font-size: 1rem;
    margin-bottom: 10px;
}

