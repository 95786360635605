footer {
  display: flex;
  flex-direction: column;
  background-color: #191919;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 3vw;
  padding: 72px 8vw;
  color: #e2e0e5;
}
.footer .h5 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 12px;
}
.footer .brand .h5 {
  display: flex;
  text-transform: uppercase;
  gap: 16px;
}
.footer > div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px;
}
.footer .brand {
  max-width: 200px;
  gap: 24px;
  line-height: 1.5;
}

footer .newsletter {
  position: relative;
}
footer .newsletter input {
  font-size: 16px;
  font-weight: 400;
  height: 53px;
  padding: 6px 6px 6px 16px;
  background: transparent;
  border: 1px solid #e2e0e5;
  color: #e2e0e5;
  outline: none;
  border-radius: 14px;
  font-size: 16px;
  width: 370px;
}
footer .newsletter button {
  position: absolute;
  padding: 16px 40px;
  height: 53px;
  border-radius: 10px;
  border: none;
  background-color: #7A1928;
  font-size: 16px;
  font-weight: 500;
  color: #3a0ca3;
  right: 6px;
  top: 6px;
  transition: all 0.5s;
}
footer .newsletter button:hover {
  cursor: pointer;
  border-radius: 70px 10px 70px 10px;
  box-shadow: -10px 0px #8872a8;
}

footer .social {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
footer .social .social-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  background-color: rgba(255, 255, 255, 0.21);
  border-radius: 20% 20% 20% 20%;
}
footer .social a {
  transition: all 0.3s ease;
}
footer .social a:hover {
  box-shadow: 0px 0px 20px 1px #726193;
  transform: scale(1.1);
}

footer .copyright {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 32px 8vw;
  background-color: #7A1928;
  color: #ffffff;
}
footer .copyright a {
  color: #ffffff;
  margin: 0 1vw 0 1vw;
}
footer .copyright a:last-child {
  margin-right: 0;
}
footer .copyright a::before {
  background-color: #ffffff !important;
}

footer p,
footer a {
  font-size: 16px;
  font-weight: 400;
}

footer a {
  color: #e2e0e5;
  text-decoration: none;
}

footer a {
  position: relative;
}
footer a:not(.social-icon)::before {
  background-color: #e2e0e5;
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  border-radius: 4px;
  bottom: 0;
  left: 0;
  transform-origin: right;
  transform: scaleX(0);
  transition: transform 0.3s ease-in-out;
}
footer a:hover::before {
  transform-origin: left;
  transform: scaleX(1);
}

@media (max-width: 960px) {
  .footer {
    gap: 72px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .footer .brand {
    max-width: 400px !important;
  }

  footer .subscribe {
    display: block;
    grid-column-start: 1;
    grid-column-end: 3;
  }
  footer .subscribe .newsletter {
    width: 100%;
    max-width: 370px;
  }
  footer .subscribe .newsletter input {
    width: calc(100% - 22px);
  }

  footer .copyright {
    padding: 32px 5vw;
  }
  footer .copyright p,
  footer .copyright a {
    font-size: 14px;
  }

  footer .footer {
    padding: 72px 8vw;
  }
}
@media (max-width: 360px) {
  footer p,
  footer a {
    font-size: 14px;
  }

  .footer .h5 {
    font-size: 16px;
  }
}