.container3 {
    display: grid;
    grid-gap: 5px;
    grid-template-areas:
      'A'
      'B' 
      'C'
      'D'   
      'E';
  }
  @media (min-width: 576px) {
    .container3 {
      grid-template-columns: 1.52fr 1fr 1fr 1.525fr;
      grid-template-areas:
        'A  B  C  E'
        'A  D  D  E';
    } 
  }
  
  .container3 .article:nth-child(1) {
   grid-area: A;
  }
  .container3 .article:nth-child(2) {
   grid-area: B;
  }
  .container3 .article:nth-child(3) {
   grid-area: C;
  }
  .container3 .article:nth-child(4) {
   grid-area: D;
  }
  .container3 .article:nth-child(5) {
   grid-area: E;
  }
  .container3 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  

  .container3 .article {
    position: relative;
    width: 100%;

    height: auto;
    overflow: hidden;
  }


  .article-content {
    height: 100%;
    padding: 15px;
    position: absolute;
    bottom: 0;
    background: rgba(0, 0, 0, 0.6);
    transition: 0.5s;
    cursor: pointer;
  }

  .article:hover .article-content {
    background: rgba(0, 0, 0, 0.9);
    transform: translateY(10%);
  }

  .article-content h2 {
    color: #fff;
    font-size: 20px;
    margin: 0;
  }

    .article-content p {
        color: #fff;
        font-size: 14px;
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
    }

