@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

@font-face {
  font-family: 'El Messiri';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('./ElMessiri.woff2') format('woff2');
  unicode-range: U+0600-06F;
}


* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.btn-primary {
  font-family: "El Messiri", "Poppins", sans-serif !important;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: "El Messiri", "Poppins", sans-serif;
  background-color: #ffffff;
  
}

input::placeholder {
  font-family: "El Messiri", "Poppins", sans-serif;
}
.background {
  background-image: url("./bg-image.jpg");
  background-attachment: fixed;

  padding: 50px 0;
}
.container {
  max-width: 90%;
  margin: auto;
}
.d_flex {
  display: flex;
  justify-content: space-between;
}
.c_flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.f_flex {
  display: flex;
}
a {
  text-decoration: none;
  color: black;
}
li {
  list-style: none;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
.RText {
  text-align-last: right;
}
input,
button {
  border: none;
  outline: none;
  background-color: none;
}
.row {
  width: 50%;
}
.icon-circle {
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: #f3f5f9;
  margin-left: 20px;
  text-align: center;
  border-radius: 50%;
}
.boxShadow {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
}

/*---------category----------*/
.contentWidth {
  width: 77%;
  display: block;
}

@media (max-width: 768px) {
  .contentWidth {
    width: 100%;
  }
}
.category {
  box-shadow: rgb(43 52 69 / 10%) 0px 4px 16px;
  background-color: #fff;
  width: 23%;
  height: 510px;
  padding: 15px 0;
  border-radius: 5px;
  margin-top: 5px;
}
.category img {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  object-fit: contain;
}
.category span {
  margin: 10px 20px;
  text-transform: capitalize;
}
.category .box {
  transition: 0.5s;
  padding: 0 20px;
}
.category .box:hover {
  background-color: #ffe1e6;
  cursor: pointer;
}

/*---------category----------*/
.btn-primary {
  background: #7A1928;
  padding: 13px 40px;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
}
.top {
  margin-top: 80px;
}
.mtop {
  margin-top: 40px;
}
.heading i {
  color: #7A1928;
  margin: 10px;
}
.discount {
  position: absolute;
  top: 0;
  left: 0;
  background: #7A1928;
  padding: 3px 10px;
  font-size: 12px;
  border-radius: 50px;
  color: #fff;
  margin: 10px;
}
/*---------product-----------*/
.product {
  background: #fff;
  padding: 20px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}

.service {
  background: #fff;
  position: relative;
  border-radius: 8px;
  box-shadow: rgb(3 0 71 / 9%) 0px 1px 3px;
  margin: 10px;
}
.product h3 {
  font-weight: 400;
  font-size: 17px;
}
.product .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}
.product .price {
  display: flex;
  justify-content: space-between;
  color: #7A1928;
}

.service h3 {
  font-weight: 400;
  font-size: 17px;
}
.service .rate i {
  font-size: 15px;
  color: #ffcd4e;
  margin: 5px 5px 5px 0;
}
.service .price {
  display: flex;
  justify-content: space-between;
  color: #7A1928;
}


.price button {
  background: none;
  color: #7A1928;
  font-size: 20px;
  transition: 0.5s;
  border: 1px solid rgb(3 0 71 / 9%);
  width: 35px;
  height: 35px;
  border-radius: 5px;
}
.price button:hover {
  cursor: pointer;
  background-color: #7A1928;
  color: #fff;
}
.product-like {
  position: absolute;
  top: 0;
  right: 0;
  margin: 10px;
  opacity: 0;
  transition: 0.5s;
}
.product-like label {
  background-color: #0f3460;
  padding: 1px 10px;
  font-size: 12px;
  color: white;
  border-radius: 50px;
}
.product-like i {
  font-size: 20px;
  margin: 10px 3px;
}
.product:hover .product-like {
  opacity: 1;
}
/*---------product-----------*/
/*----- control btn -------*/
.control-btn .next,
.control-btn .prev {
  position: absolute;
  top: 40%;
  width: 50px;
  height: 50px;
  line-height: 60px;
  background: #0f3460;
  border-radius: 50%;
  transform: 0.5s;
  z-index: 5;
}
.control-btn .prev {
  left: -20px;
}
.control-btn .next {
  right: -20px;
}
.control-btn .prev i,
.control-btn .next i {
  font-size: 20px;
  transition: 0.5s;
  color: #fff;
}
.control-btn .prev:hover,
.control-btn .next:hover {
  background: #7A1928;
  color: white;
  cursor: pointer;
}
/*----- control btn -------*/
/*----- heading -------*/
.heading .heading-left i {
  font-size: 30px;
}
.heading .heading-left h2 {
  font-size: 25px;
  margin: 5px;
}
.heading .heading-right i,
.heading .heading-right {
  text-align: right;
  color: grey;
}
/*----- heading -------*/
.grid {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 30px;
}
.grid2 {
  display: grid;
  grid-gap: 10px;
}

.avatar {
  object-fit: contain;
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

.cc {
  margin: auto;
  max-width: 90%;
}