.flash {
  background: #f6f9fc;
  padding: 50px 0;
}


.flash .img {
  cursor: pointer;
  height: 300px;

}

.flash img {
  object-fit: contain;
  height: 100%;
}

.discounted-price {
  margin-right: 10px;
  font-size: 0.8rem;
  color: #7A1928;
  text-decoration: line-through;

}

