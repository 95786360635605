.tabs {
    align-items: center;
    background: white;
    width: 74px;
    justify-content: center;
}

.activeTabs {
    background: white;
    width: 74px;
}

.barFont {
    font-size: 10px;
}

.activeContent {
    display: block;
}

#app {
    background: #191919;

    height: "100vh";
}
.barContainer {
    display: flex;
    height: 308px;
    width: 300px;
    margin-left: 20px;

}

.barBox {
    justify-content: space-between;
    align-items: center;

}

.bar {
    background: #7A1928;
    padding: 4px;
    margin-left: 5px;
    justify-content: space-between;

}

.color-picker {
    margin-top: 50px;
    background: #191919;

}

.image-picker {
    background: #191919;

}


#app-sidebar {
    background: #191919;
    width: 300px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

#app-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    min-height: 100vh;
    color: white;
}

.file-upload {
    text-align: center;
    border: 2px dashed rgba(210, 227, 244, 0.247);
    padding: 1.5rem;
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    margin-bottom: 20px;
  }
  .file-upload p {
    font-size: 0.87rem;
    margin-top: 10px;
    color: #bbcada;
  }
  .file-input {
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
    cursor: pointer;
    }


    .app-title-icon {
        margin-right: 10px;
        color: #ffffff6c;
        display: inline-block;

    }
    .app-title {
        margin-bottom: 20px;
        color: #ffffff6c;
        display: inline-block;
        
    }


    #customize-quantity {
        background: none;
        outline: none;
        border: none;
        line-height: 1;
        font-weight: 600;
        font-size: 1.1rem;
        color: #333;
    }
    .numberstyle-qty-container {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        margin-top: 20px;
    }
    .numberstyle-qty {
        width: 50%;
        position: relative;
        border-radius: 6px;
        background-color: #ffffff;
        overflow: hidden;
        box-shadow: 0 3px 6px rgba(116, 116, 116, 0.25);
      }
      
      .numberstyle-qty input,
      .numberstyle-qty .qty-btn {
        position: relative;
        display: block;
        float: left;
        height: 40px;
        padding: 0;
        margin: 0;
        border: 0px;
        text-align: center;
        line-height: 40px;
        outline: 0;
        box-shadow: 0px;
      }
      
      .numberstyle-qty .qty-btn {
        width: 40px;
        font-size: 18px;
        cursor: pointer;
        z-index: 2;
        -webkit-user-select: none;
           -moz-user-select: none;
            -ms-user-select: none;
                user-select: none;
        transition: all 250ms ease;
      }
      
      .numberstyle-qty .qty-btn.disabled {
        cursor: default;
        color: rgba(116, 116, 116, 0.5);
      }
      
      .numberstyle-qty input {
        width: 50px;
        border-left: 0;
        border-right: 0;
        color: #747474;
        font-size: 18px;
        font-weight: 700;
        z-index: 1;
      }
      
      .numberstyle-qty input:focus {
        outline: 0;
        box-shadow: 0px;
      }
      
      .numberstyle-qty input[type=number]::-webkit-inner-spin-button,
      .numberstyle-qty input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      
      .numberstyle-qty input[type=number] {
        -moz-appearance: textfield;
      }

      .add-to-cart {
        background: #7A1928;
        color: white;
        padding: 10px 20px;
        border-radius: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
        margin-top: 20px;
      }