.cart-items {
  background: #f6f9fc;
  height: auto;
  padding: 50px 0;
}

.cart-items .cart-details {
  width: 70%;
}

.cart-d {
  width: 30%;
  display: flex;
  align-items: center;
  flex-direction: column;

}

.t_flex {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

}

.t_flex .delivery-type {
  width: 30%;
  height: 40px;
  background: #7A1928;
  opacity: 0.5;
  color: white;
  margin: 10px;
  border: none;
  border-radius: 5px;
  font-size: 15px;
  cursor: pointer;
}

.t_flex .delivery-type.active {
  background: #7A1928;
  opacity: 1;
}


.t_flex .payment-type {
  width: 50%;
  height: 50px;
  opacity: 0.3;
  color: black;
  margin: 10px;
  border: 1px solid rgb(3 0 71 / 9%);
  border-radius: 5px;

  font-size: 15px;
  cursor: pointer;
}

.t_flex .payment-type.active {
  opacity: 1;
}


.input {
  width: 80%;
  height: 40px;
  border: 1px solid rgb(3 0 71 / 9%);
  border-radius: 5px;
  padding: 10px;
  margin-top: 20px;
  font-family: 'Poppins', sans-serif;

}

.slct {
  cursor: pointer;
}


.cart-items .cart-total {
  width: 100%;
  margin-top: 30px;
  margin-left: 30px;
  height: 180px;
}
.cart-list {
  background: white;
  margin-top: 30px;
}
.cart-items .img {
  width: 150px;
  height: 150px;
}
.cart-items img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart-items .cart-details h3 {
  font-size: 20px;
  font-weight: 500;
  margin-top: 20px;
}
.cart-items .cart-details h4 {
  font-size: 15px;
  font-weight: 400;
  margin-top: 50px;
  color: grey;
}
.cart-items h4 span {
  color: #7A1928;
  margin-left: 20px;
  font-weight: 500;
}
.removeCart {
  background: none;
  font-size: 25px;
  text-align: right;
  margin-right: 10px;
}
.cartControl {
  margin-top: 50px;
}
.cartControl button {
  width: 40px;
  height: 40px;
  margin: 10px;
  border-radius: 5px;
  font-size: 20px;
}
.incCart {
  background: none;
  border: 1px solid rgb(3 0 71 / 9%);
  color: #7A1928;
}
.desCart {
  background: #f6f9fc;
}
.cart-total h4 {
  font-size: 15px;
  font-weight: 400;
}
.cart-total h3 {
  font-size: 20px;
  font-weight: 500;
  color: #7A1928;
}
.cart-total h2 {
  font-size: 18px;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(3 0 71 / 9%);
  padding-bottom: 10px;
  color: #7A1928;
}
.no-items {
  color: #7A1928;
  font-size: 18px;
  margin-top: 30px;
  height: 130px;
}


.incCart, .desCart, .removeCart {
  cursor: pointer;
}

.step-progress {
  width: 100%;

}

.container {
  display: flex;
  width: 100%;
}