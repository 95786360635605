.TopCate {
  position: relative;
}
.TopCate img {
  width: 100%;
  height: 100%;
  border-radius: 5px;
}
.TopCate .nametop {
  position: relative;
  width: 100%;
}
.TopCate .nametop span {
  background: #0f3460;
  color: #fff;
  font-size: 12px;
  padding: 5px 10px;
  border-radius: 50px;
  margin: 5px;
  position: absolute;
  top: 10px;
}
.TopCate .nametop .tleft {
  left: 0;
}
.TopCate .nametop .tright {
  background-color: aliceblue;
  color: black;
  position: absolute;
  right: 0;
}


.heading img {
  width: 40px;
  height: 40px;
  margin-top: 5px;
}
.TopCate h4 {
  font-weight: 400;
}
.TopCate span {
  color: #7A1928;
  font-size: 15px;
  font-weight: 500;
}

.TopCate .box {
  margin-left: 10px !important;
  border-radius: 5px;
  width: 250px;
  height: 250px;

  cursor: pointer;

}




.TopCate .box img {
  object-fit: contain;
  width: 100%;
}

.flex-1 {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.skelton {
  margin: 20px;
}

