.flash {
  background: #f6f9fc;
  padding: 50px 0;
}

.flash .service-img {
  cursor: pointer;
  height: 300px;
  overflow: hidden;

}

.service-img img {
  object-fit: cover !important;
  height: 100%;
  overflow: hidden;

}
.rtl {
  direction: rtl;

}
.discounted-price {
  margin-right: 10px;
  font-size: 0.8rem;
  color: #7A1928;
  text-decoration: line-through;

}

.customize-btn {
  padding: 5px 20px;
  background: none;
  text-align: center;
  font-size: 17px;
  font-weight: 500;
  background: #7A1928;
  color: #fff;
  border-radius: 5px;
  cursor: pointer;
  transition: 0.5s;
}

.customize-btn:hover {
  background: #fff;
  color: #7A1928;
}
.product-details2 {
  padding: 20px;
}
