.file-upload *,.file-upload:before,.file-upload:after{
    box-sizing:border-box;
    border-width:0;
    border-style:solid;
    border-color:#e5e7eb
}
.file-upload :before,:after{
    --tw-content: ""
}
.file-upload html,:host{
    line-height:1.5;
    -webkit-text-size-adjust:100%;
    -moz-tab-size:4;
    -o-tab-size:4;
    tab-size:4;
    font-family:ui-sans-serif,system-ui,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
    font-feature-settings:normal;
    font-variation-settings:normal;
    -webkit-tap-highlight-color:transparent
}

.file-upload hr{
    height:0;
    color:inherit;
    border-top-width:1px
}
.file-upload abbr:where([title]){
    -webkit-text-decoration:underline dotted;
    text-decoration:underline dotted
}
.file-upload h1,.file-upload h2,.file-upload h3,.file-upload h4,.file-upload h5,.file-upload h6{
    font-size:inherit;
    font-weight:inherit
}
.file-upload a{
    color:inherit;
    text-decoration:inherit
}
.file-upload b,.file-upload strong{
    font-weight:bolder
}
.file-upload code,.file-upload kbd,.file-upload samp,.file-upload pre{
    font-family:ui-monospace,SFMono-Regular,Menlo,Monaco,Consolas,Liberation Mono,Courier New,monospace;
    font-feature-settings:normal;
    font-variation-settings:normal;
    font-size:1em
}
.file-upload small{
    font-size:80%
}
.file-upload sub,.file-upload sup{
    font-size:75%;
    line-height:0;
    position:relative;
    vertical-align:baseline
}
.file-upload sub{
    bottom:-.25em
}
.file-upload sup{
    top:-.5em
}
.file-upload table{
    text-indent:0;
    border-color:inherit;
    border-collapse:collapse
}
.editor button,.editor input,.editor optgroup,.editor select,.editor textarea{
    font-family:inherit;
    font-feature-settings:inherit;
    font-variation-settings:inherit;
    font-size:100%;
    font-weight:inherit;
    line-height:inherit;
    letter-spacing:inherit;
    color:inherit;
    margin:0;
    padding:0
}
.editor button,select{
    text-transform:none
}
.photo-editor-main button,input:where([type=button]),input:where([type=reset]),input:where([type=submit]){
    -webkit-appearance:button;
    background-color:transparent;
    background-image:none
}
.photo-editor-main:-moz-focusring{
    outline:auto
}
.photo-editor-main:-moz-ui-invalid{
    box-shadow:none
}
.photo-editor-main  progress{
    vertical-align:baseline
}
.photo-editor-main::-webkit-inner-spin-button,.photo-editor-main::-webkit-outer-spin-button{
    height:auto
}
.photo-editor-main[type=search]{
    -webkit-appearance:textfield;
    outline-offset:-2px
}
.photo-editor-main::-webkit-search-decoration{
    -webkit-appearance:none
}
.photo-editor-main::-webkit-file-upload-button{
    -webkit-appearance:button;
    font:inherit
}
.photo-editor-main summary{
    display:list-item
}



.file-upload[hidden]{
    display:none
}

.file-upload .fixed{
    position:fixed
}
.file-upload .absolute{
    position:absolute
}
.file-upload .relative{
    position:relative
}
.file-upload .inset-0{
    top:0;
    right:0;
    bottom:0;
    left:0
}
.file-upload .bottom-0{
    bottom:0
}
.file-upload.bottom-12{
    bottom:3rem
}
.file-upload .z-40{
    z-index:40
}
.file-upload .z-50{
    z-index:50
}
.file-upload .m-1{
    margin:.25rem
}
.file-upload .mx-1{
    margin-left:.25rem;
    margin-right:.25rem
}
.file-upload .mx-auto{
    margin-left:auto;
    margin-right:auto
}
.file-upload .mb-1{
    margin-bottom:.25rem
}
.file-upload .ml-2{
    margin-left:.5rem
}
.file-upload .ml-\[1\.7rem\]{
    margin-left:1.7rem
}
.file-upload .mt-2{
    margin-top:.5rem
}
.file-upload.mt-4{
    margin-top:1rem
}
.file-upload .inline-block{
    display:inline-block
}
.file-upload .flex{
    display:flex
}
.file-upload .h-1{
    height:.25rem
}
.file-upload .h-\[38rem\]{
    height:38rem
}
.file-upload .max-h-\[22rem\]{
    max-height:22rem
}
.file-upload .w-10{
    width:2.5rem
}
.file-upload .w-11\/12{
    width:91.666667%
}
.file-upload .w-14{
    width:3.5rem
}
.file-upload .w-\[40rem\]{
    width:40rem
}
.file-upload .w-full{
    width:100%
}
.file-upload .max-w-xl{
    max-width:36rem
}
.file-upload .cursor-grab{
    cursor:grab
}
.file-upload .cursor-grabbing{
    cursor:grabbing
}
.file-upload .cursor-pointer{
    cursor:pointer
}
.file-upload .appearance-none{
    -webkit-appearance:none;
    -moz-appearance:none;
    appearance:none
}
.file-upload .flex-row{
    flex-direction:row
}
.file-upload .flex-col{
    flex-direction:column
}
.file-upload .items-center{
    align-items:center
}
.file-upload .justify-end{
    justify-content:flex-end
}
.file-upload .justify-center{
    justify-content:center
}
.file-upload .gap-1{
    gap:.25rem
}
.file-upload .overflow-auto{
    overflow:auto
}
.file-upload .rounded-full{
    border-radius:9999px
}
.file-upload.rounded-lg{
    border-radius:.5rem
}
.file-upload.rounded-md{
    border-radius:.375rem
}
.file-upload .rounded-t{
    border-top-left-radius:.25rem;
    border-top-right-radius:.25rem
}
.file-upload.border{
    border-width:1px
}
.file-upload.border-gray-300{
    --tw-border-opacity: 1;
    border-color:rgb(209 213 219 / var(--tw-border-opacity))
}
.file-upload .bg-black{
    --tw-bg-opacity: 1;
    background-color:rgb(0 0 0 / var(--tw-bg-opacity))
}
.file-upload .bg-gray-100{
    --tw-bg-opacity: 1;
    background-color:rgb(243 244 246 / var(--tw-bg-opacity))
}
.file-upload .bg-gray-200{
    --tw-bg-opacity: 1;
    background-color:rgb(229 231 235 / var(--tw-bg-opacity))
}
.file-upload .bg-white{
    --tw-bg-opacity: 1;
    background-color:rgb(255 255 255 / var(--tw-bg-opacity))
}
.file-upload .p-1{
    padding:.25rem
}
.file-upload .p-2{
    padding:.5rem
}
.file-upload .px-2{
    padding-left:.5rem;
    padding-right:.5rem
}
.file-upload .py-1{
    padding-top:.25rem;
    padding-bottom:.25rem
}
.file-upload .text-right{
    text-align:right
}
.file-upload .text-sm{
    font-size:.875rem;
    line-height:1.25rem
}
.file-upload .text-xs{
    font-size:.75rem;
    line-height:1rem
}
.file-upload .font-medium{
    font-weight:500
}
.file-upload .text-black{
    --tw-text-opacity: 1;
    color:rgb(0 0 0 / var(--tw-text-opacity))
}
.file-upload .text-gray-900{
    --tw-text-opacity: 1;
    color:rgb(17 24 39 / var(--tw-text-opacity))
}
.file-upload .opacity-75{
    opacity:.75
}
.file-upload .shadow-lg{
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow:var(--tw-ring-offset-shadow, 0 0 #0000),var(--tw-ring-shadow, 0 0 #0000),var(--tw-shadow)
}
.file-upload .grayscale{
    --tw-grayscale: grayscale(100%);
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.file-upload .filter{
    filter:var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}
.file-upload .hover\:bg-gray-100:hover{
    --tw-bg-opacity: 1;
    background-color:rgb(243 244 246 / var(--tw-bg-opacity))
}
.file-upload .focus\:outline-none:focus{
    outline:2px solid transparent;
    outline-offset:2px
}
.file-upload .focus\:ring-2:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}
.file-upload .focus\:ring-4:focus{
    --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
    --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(4px + var(--tw-ring-offset-width)) var(--tw-ring-color);
    box-shadow:var(--tw-ring-offset-shadow),var(--tw-ring-shadow),var(--tw-shadow, 0 0 #0000)
}
.file-upload .focus\:ring-gray-100:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(243 244 246 / var(--tw-ring-opacity))
}
.file-upload .focus\:ring-gray-300:focus{
    --tw-ring-opacity: 1;
    --tw-ring-color: rgb(209 213 219 / var(--tw-ring-opacity))
}
@media not all and (min-width: 640px){
    .file-upload .max-sm\:w-72{
        width:18rem
    }
    .file-upload .max-sm\:w-\[22rem\]{
        width:22rem
    }
}
@media (prefers-color-scheme: dark){
    .file-upload .dark\:border-gray-600{
        --tw-border-opacity: 1;
        border-color:rgb(75 85 99 / var(--tw-border-opacity))
    }
    .file-upload .dark\:border-gray-700{
        --tw-border-opacity: 1;
        border-color:rgb(55 65 81 / var(--tw-border-opacity))
    }
    .file-upload .dark\:bg-\[\#1e1e1e\]{
        --tw-bg-opacity: 1;
        background-color:rgb(30 30 30 / var(--tw-bg-opacity))
    }
    .file-upload .dark\:bg-gray-700{
        --tw-bg-opacity: 1;
        background-color:rgb(55 65 81 / var(--tw-bg-opacity))
    }
    .file-upload .dark\:bg-gray-800{
        --tw-bg-opacity: 1;
        background-color:rgb(31 41 55 / var(--tw-bg-opacity))
    }
    .file-upload .dark\:stroke-slate-200{
        stroke:#e2e8f0
    }
    .file-upload .dark\:text-white{
        --tw-text-opacity: 1;
        color:rgb(255 255 255 / var(--tw-text-opacity))
    }
    .file-upload .dark\:hover\:border-gray-600:hover{
        --tw-border-opacity: 1;
        border-color:rgb(75 85 99 / var(--tw-border-opacity))
    }
    .file-upload .dark\:hover\:bg-gray-700:hover{
        --tw-bg-opacity: 1;
        background-color:rgb(55 65 81 / var(--tw-bg-opacity))
    }
    .file-upload .dark\:focus\:ring-gray-700:focus{
        --tw-ring-opacity: 1;
        --tw-ring-color: rgb(55 65 81 / var(--tw-ring-opacity))
    }
}
 